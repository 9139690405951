import { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Collapse, Flex, Select, theme, Typography } from 'antd';
import { CheckCircleOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { useParams, useSearchParams } from 'react-router-dom';
import { UserContext, UserType } from '../../UserContext';
import { isAdmin, isUserLoggedIn } from '../../Insights/Auth';
import { SignInButton } from '../../SignInButton';
import { Agent, Task, TasksResponse } from '../../types';
import { TaskTitle } from './task-title';
import './styles.css';
import { TaskContent } from './task-content';
import CustomAlert from '../../CustomAlert';

const { Title, Text } = Typography;

export const AgentLeads = ({ apiUrl }: { apiUrl: string }): JSX.Element => {
  const [tasks, setTasks] = useState<Task[]>([]);
  // const [taskCount, setTaskCount] = useState(0);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [currentAgent, setCurrentAgent] = useState<Agent | undefined>(
    undefined,
  );
  const [error, setError] = useState<string | undefined>(undefined);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined,
  );
  const { clientId, agentId } = useParams<{
    clientId: string;
    agentId: string;
  }>();
  const searchParams = useSearchParams()[0];
  const activeTask = searchParams.get('activeTask');
  const taskAction = activeTask != null ? searchParams.get('action') : null;
  const useAgentView = searchParams.get('agentView') === '1' || agentId != null;
  const showAll = searchParams.get('showAll') === '1';
  // (searchParams.get('agentView') === '1' && isAdmin()) || agentId != null;
  const user = useContext<UserType>(UserContext);
  const userIsAdmin = isAdmin();
  const lastCreatedAt = useRef<string | undefined>();

  const defaultAlertMessage =
    tasks[0] != null
      ? taskAction === 'text_sent' && tasks[0].checked_off
        ? `Text sent to ${tasks[0].lead.first_name} ${tasks[0].lead.last_name}`
        : taskAction === 'email_link'
          ? 'Link sent to your email'
          : undefined
      : undefined;

  useEffect(() => {
    setAlertMessage(defaultAlertMessage);
  }, [defaultAlertMessage]);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/tasks/agents/${clientId}?offset=0&limit=30`,
        );
        setAgents(response.data.agents);
        if (agentId) {
          setCurrentAgent(
            response.data.agents.find((a: Agent) => a.id === agentId),
          );
        } else if (response.data.agents.length > 0) {
          setCurrentAgent(response.data.agents[0]);
        }
      } catch (error) {
        console.error('Failed to fetch agents:', error);
      }
    };
    fetchAgents().catch(error =>
      console.error('Failed to execute fetchAgents:', error),
    );
  }, []);

  useEffect(() => {
    const fetchTasks = async () => {
      if (!currentAgent) {
        return;
      }
      const created_at = lastCreatedAt.current || '';
      try {
        const response: { data: TasksResponse } = await axios.get(
          `${apiUrl}/api/Tasks/${clientId}/${currentAgent.id}?created_at=${created_at}&show_all=${showAll ? 1 : 0}`,
        );
        const newTasks: Task[] = response.data.tasks;
        setTasks(prev => {
          if (activeTask) {
            if (prev.length > 0) {
              return [prev[0], ...response.data.tasks, ...prev.slice(1)];
            }
            const active = response.data.tasks.find(
              task => task.id === activeTask,
            );
            if (active == null) {
              return response.data.tasks;
            }
            const sortedTasks = [
              active,
              ...response.data.tasks.filter(task => task.id !== activeTask),
            ];
            return sortedTasks;
          }
          return [...response.data.tasks, ...prev];
        });
        if (newTasks.length > 0) {
          const maxCreatedAt = newTasks.reduce((max, task) => {
            if (task.created_at > max) {
              return task.created_at;
            }
            return max;
          }, '');

          lastCreatedAt.current = maxCreatedAt;
        }
        // setTaskCount(response.data.task_count);
      } catch (error) {
        console.error('Failed to fetch leads:', error);
      }
    };

    // Call fetchTasks immediately
    void fetchTasks();

    // Set up interval to fetch tasks every 30 seconds
    const intervalId = setInterval(() => {
      void fetchTasks();
    }, 30000);

    // Clean up the interval when the component unmounts or when currentAgent changes
    return () => {
      clearInterval(intervalId);
      setTasks([]);
      lastCreatedAt.current = undefined;
    };
  }, [currentAgent]);

  const toggleTaskCompletion = async (taskToToggle: Task) => {
    // Toggle the completion status
    const updatedStatus = !taskToToggle.checked_off;

    try {
      await axios.post(
        `${apiUrl}/api/Tasks/${clientId}/${currentAgent?.id}/${taskToToggle.id}`,
        {
          checked_off: updatedStatus,
          checked_off_by: user?.userID || agentId,
        },
      );
    } catch (error) {
      console.error('Failed to fetch leads:', error);
      return;
    }

    // Update local state
    setTasks(tasks => {
      const updatedTasks = [...tasks];
      const taskIndex = updatedTasks.findIndex(
        task => task.id === taskToToggle.id,
      );
      if (taskIndex !== -1) {
        updatedTasks[taskIndex] = {
          ...updatedTasks[taskIndex],
          checked_off: updatedStatus,
        };
      }

      return updatedTasks;
    });
  };

  const pushToSend = async (task: Task, message?: string) => {
    try {
      const res = await axios.post(
        `${apiUrl}/api/pts/send_message/${task.id}`,
        {
          checked_off_by: user?.userID || agentId,
          message,
        },
      );
      if (res.status !== 200) {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      setError(
        'Failed to send message, please contact aiassistant@housewhisper.ai',
      );
      return;
    }

    setTasks(tasks => {
      const updatedTasks = [...tasks];
      const taskIndex = updatedTasks.findIndex(t => t.id === task.id);
      if (taskIndex !== -1) {
        updatedTasks[taskIndex] = {
          ...updatedTasks[taskIndex],
          checked_off: true,
        };
      }
      return updatedTasks;
    });
    setAlertMessage(
      `Text sent to ${task.lead.first_name} ${task.lead.last_name}`,
    );
  };

  const dislikeClicked = async (task: Task) => {
    const isRejected =
      (!userIsAdmin && !task.rejected) ||
      (userIsAdmin && !task.rejected_by_admin);

    console.log('Dislike clicked', task, isRejected, userIsAdmin);

    try {
      await axios.post(`${apiUrl}/api/tasks/reject/${task.id}`, {
        is_rejected: isRejected,
        is_admin: userIsAdmin,
        rejected_by: user?.userID || agentId,
      });
    } catch (error) {
      console.error('Failed to fetch leads:', error);
      return;
    }

    setTasks(tasks => {
      const updatedTasks = [...tasks];
      const taskIndex = updatedTasks.findIndex(t => t.id === task.id);
      if (taskIndex !== -1) {
        updatedTasks[taskIndex] = {
          ...updatedTasks[taskIndex],
          rejected: !userIsAdmin ? !task.rejected : task.rejected,
          rejected_by: user?.userID || agentId,
          rejected_by_admin: userIsAdmin
            ? !task.rejected_by_admin
            : task.rejected_by_admin,
        };
      }
      return updatedTasks;
    });
  };

  const { token } = theme.useToken();

  const items = tasks.map(task => ({
    key: `${task.id}`,
    headerClass: 'header',
    task: task,
    label: (
      <Flex align="center" justify="space-between">
        <Flex flex={1} gap={0} style={{ maxWidth: '80%' }} vertical>
          <Flex align="center" gap="small" justify="flex-start" wrap="wrap">
            <Text
              style={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                color: '#28638A',
              }}
            >
              {task.lead.first_name} {task.lead.last_name}{' '}
              {!useAgentView && (
                <span>
                  <Text
                    copyable
                    ellipsis={true}
                    style={{ color: 'red', width: 50 }}
                  >
                    {task.id}
                  </Text>
                  {task.is_committed ? 'Committed' : 'Not'} {task.due_date}{' '}
                  {task.scheduled_date}
                </span>
              )}
            </Text>
          </Flex>
          <TaskTitle task={task} />
        </Flex>

        <div
          onClick={event => {
            event.stopPropagation();
            toggleTaskCompletion(task).catch(error =>
              console.error('Failed to toggle task completion:', error),
            );
          }}
          style={{
            height: '3em',
            width: '3em',
          }}
        >
          {task.checked_off ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CheckCircleTwoTone
                style={{ fontSize: '3em' }}
                twoToneColor="#52c41a"
              />
            </div>
          ) : (
            <CheckCircleOutlined
              style={{ color: '#28638A', fontSize: '3em' }}
            />
          )}
        </div>
      </Flex>
    ),
    children: (
      <TaskContent
        dislikeClicked={dislikeClicked}
        editMode={taskAction === 'edit' && activeTask === task.id}
        pushToSend={pushToSend}
        task={task}
      />
    ),
    style: {
      marginBottom: 24,
      background: '#e0e3e8',
      borderRadius: token.borderRadiusLG,
      border: 'none',
      textAlign: 'left',
    },
    showArrow: false,
  }));

  const agentOptions = agents.map(agent => ({
    value: agent.id,
    label: agent.first_name + ' ' + agent.last_name + ' ' + agent.id,
  }));

  const nonActiveTasks = items.filter(item => item.task.id !== activeTask);
  const activeTaskItem = items.find(item => item.task.id === activeTask);

  const smartTasks = nonActiveTasks.filter(
    item =>
      item.task.task_type != 'call_follow_up' &&
      item.task.task_type != 'time_hot',
  );
  const hotLeadTasks = nonActiveTasks.filter(
    item => item.task.task_type == 'time_hot',
  );
  const callFollowUpTasks = nonActiveTasks.filter(
    item => item.task.task_type === 'call_follow_up',
  );

  return (
    <div style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
      {!isUserLoggedIn() && !agentId ? (
        <SignInButton />
      ) : (
        <span>
          <CustomAlert
            message={error || alertMessage}
            type={error != null ? 'error' : 'success'}
          />
          {isAdmin() && !agentId && agents.length > 0 ? (
            <div>
              <span style={{ color: 'red' }}>Admin</span>
              <Select
                onChange={(value: string) =>
                  setCurrentAgent(agents.find(a => a.id === value))
                }
                options={agentOptions}
                style={{ width: 120 }}
                value={currentAgent?.id}
              />
            </div>
          ) : null}
          <Title
            level={2}
            style={{
              fontFamily: 'Roboto Slab',
              // fontSize: '28px',
              fontWeight: '500',
              // lineHeight: '36px',
              // letterSpacing: '0em',
              textAlign: 'left',
            }}
          >
            Your Recommendations for today
          </Title>
          {activeTaskItem && (
            <>
              <Collapse
                defaultActiveKey={activeTaskItem.key}
                ghost
                items={[activeTaskItem]}
              />
            </>
          )}
          {smartTasks.length > 0 && (
            <>
              <Title
                level={5}
                style={{
                  fontFamily: 'Roboto Slab',
                  fontWeight: '400',
                  // letterSpacing: '0em',
                  marginTop: 0,
                  textAlign: 'left',
                }}
              >
                Your Appointments & Callbacks
              </Title>
              <Collapse
                defaultActiveKey={[activeTask ?? '']}
                ghost
                items={smartTasks}
              />
            </>
          )}
          {hotLeadTasks.length > 0 && (
            <>
              <Title
                level={5}
                style={{
                  fontFamily: 'Roboto Slab',
                  fontWeight: '400',
                  // letterSpacing: '0em',
                  marginTop: 0,
                  textAlign: 'left',
                }}
              >
                Your Hot leads could use some attention.
              </Title>
              <Collapse
                defaultActiveKey={[activeTask ?? '']}
                ghost
                items={hotLeadTasks}
              />
            </>
          )}
          {callFollowUpTasks.length > 0 && (
            <>
              <Title
                level={5}
                style={{
                  fontFamily: 'Roboto Slab',
                  fontWeight: '400',
                  // letterSpacing: '0em',
                  textAlign: 'left',
                }}
              >
                Your Call follow ups
              </Title>
              <Collapse
                defaultActiveKey={[activeTask ?? '']}
                ghost
                items={callFollowUpTasks}
              />
            </>
          )}
        </span>
      )}
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  Spin,
  Typography,
  Result
} from 'antd';
import type { CheckboxChangeEvent } from 'antd';
import { BorderOutlined } from '@ant-design/icons';

const { Title } = Typography;

type Client = {
  id: string;
  clientName: string;
  friendlyName: string;
  clientState: string;
  crmType: string;
};

type VerifyResponse = {
  isRecordingEnabled: boolean;
  isSmsEnabled: boolean;
};

type BackfillResponse = {
  batchStatusId: number
}

type BatchStatus = {
  status: string  
}

interface SubmitButtonProps {
  form: FormInstance;
  onVerify: (values: any) => void;
}

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({
  form,
  onVerify,
  children,
}) => {
  const [submittable, setSubmittable] = React.useState<boolean>(false);

  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    const validate = async () => {
      try {
        const results = await form.validateFields({ validateOnly: true });
        setSubmittable(true);
      } catch {
        setSubmittable(false);
      }
    };

    validate();
  }, [form, values]);

  return (
    <Button disabled={!submittable} onClick={onVerify} type="primary">
      Verify Account
    </Button>
  );
};

const VerifyClientPage: React.FC<{ apiUrl: string }> = ({ apiUrl }) => {
  const [form] = Form.useForm();

  const getSmsMessage = (friendlyName: string | undefined) => {
    return `Hello,
    I would like to request the SMS content be enabled for API requests for ${friendlyName}.  My understanding is this is done via a feature flag on the backend.  The account owner is aware of this request and understands that their approval will be required.
    Please let us know if there are any additional steps we need to take to facilitate this process.
    Thank you for you assistance!`;
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [client, setClient] = useState<Client>();
  const [clientName, setClientNameValue] = useState<string>('');
  const [password, setPasswordValue] = useState<string | null>(null);
  const [apiKey, setApiKey] = useState<string>('');
  const [site, setSite] = useState<string>('');
  const [recordingsEnabled, setRecordingsEnabled] = useState<boolean>(false);
  const [smsAccess, setSmsAccess] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [backFillRunning, setBackfillRunning] = useState<boolean>(false);

  const { clientId } = useParams<{
    clientId: string;
  }>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClientNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClientNameValue(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordValue(e.target.value);
  };

  const handleApiKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApiKey(e.target.value);
  };

  const handleSiteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSite(e.target.value);
  };

  const showVerifyClientModal = () => {
    setIsModalOpen(true);
  };

  const handleVerifyClientOk = async () => {
    setIsModalOpen(false);
    await fetch(`${apiUrl}/api/clients/${clientId}/backfill`, {
      method: 'GET',
    })
      .then(async verifyResponse => {
        if (!verifyResponse.ok) {
          throw new Error(`${verifyResponse.status} - ${await verifyResponse.text()}`);
        }
        const backfillResponse = (await verifyResponse.json()) as BackfillResponse;
        setBackfillRunning(true);
        const intervalId = setInterval(async () => {
          await fetch(`${apiUrl}/api/Batches/${backfillResponse.batchStatusId}/status`, {
            method: 'GET',
          })
          .then(async batchResponse => {
            if (!batchResponse.ok) {
              throw new Error(`${batchResponse.status} - ${await batchResponse.text()}`);
            }
            const batchStatus = (await batchResponse.json()) as BatchStatus;
            if (batchStatus.status != "running")
            {
              setBackfillRunning(false);
              clearInterval(intervalId);
              //alert(batchStatus.status);
            }
          })
          .catch(error => {
            console.error('Error:', error);
            alert(error);
          })      
        }, 5000);
      })
      .catch(error => {
        console.error('Error:', error);
        alert(error);
      });
  };

  const handleVerifyClientCancel = () => {
    setIsModalOpen(false);
  };

  const handleRecordsingsEnabledChange = (e: CheckboxChangeEvent) => {
    setRecordingsEnabled(e.target.value);
  };

  const handleSmsAccessChange = (e: CheckboxChangeEvent) => {
    setSmsAccess(e.target.value);
  };

  const handleCopyMessage = () => {
    navigator.clipboard.writeText(getSmsMessage(client?.friendlyName));
  };

  const handleVerify = async () => {
    try {
      const values = await form.validateFields();
    } catch (errorInfo) {
      console.log('❌ Validation failed:', errorInfo);
      return;
    }

    const data = {
      client_id: clientId,
      username: clientName,
      password: password,
      apikey: apiKey,
      site: site,
    };

    await fetch(`${apiUrl}/api/clients/${clientId}/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(async response => {
        if (!response.ok) {
          throw new Error(`${response.status} - ${await response.text()}`);
        }
        const verifyResponse = (await response.json()) as VerifyResponse;
        setRecordingsEnabled(verifyResponse.isRecordingEnabled);
        setSmsAccess(verifyResponse.isSmsEnabled);
        showVerifyClientModal();
      })
      .then(data => {
        console.log('Success:', data);
        message.success('Client verified successfully');
      })
      .catch(error => {
        setValidationError(error?.message);
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    let isMounted = true;

    const fetchClient = async () => {
      setLoading(true);
      try {
        const client_response = await fetch(
          `${apiUrl}/api/clients/${clientId}`,
        );
        if (!client_response.ok) {
          throw new Error('Failed to fetch clients');
        }
        const client_data = (await client_response.json()) as Client;
        if (isMounted) {
          if (client_data.clientName) {
            setClientNameValue(client_data.clientName);
          } else {
            setClientNameValue(
              client_data.friendlyName.toLowerCase().replaceAll(' ', '_'),
            );
          }
          setClient(client_data);
        }
      } catch (error) {
        if (isMounted) {
          message.error('Failed to load client. Please try again later.');
        }
        console.error('Error fetching clients:', error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    void fetchClient();

    return () => {
      isMounted = false;
    };
  }, [apiUrl]);

  return (
    <div>
      <Title level={1}>Onboarding - Create New Client</Title>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          borderRadius: 8,
        }}
      >
        <div style={{ padding: 20, margin: '3px' }}>
          <Title level={2}>Instructions</Title>
          <ol style={{ textAlign: 'left' }}>
            <li>
              Ask client for Follow Up Boss Account API:
              <br />
              <Typography.Text strong={true}>
                api+{clientName}@housewhisper.ai
              </Typography.Text>
            </li>
            <li>
              Login to the account and configure:
              <ul>
                <li>Phone Number: (833) 242-1694</li>
                <li>Name: HouseWhisper AI</li>
                <li>
                  Icon:{' '}
                  <img
                    alt="HouseWhisper AI"
                    src="https://www.housewhisper.ai/img/logo.svg"
                  />
                </li>
              </ul>
            </li>
            <li>
              Generate API Key:
              <ol>
                <li>
                  Visit{' '}
                  <a href="https://app.followupboss.com/2/api">
                    Follow Up Boss API
                  </a>
                </li>
                <li>
                  Click "Create API Key" and name it{' '}
                  <Typography.Text strong={true}>
                    housewhisper-prod
                  </Typography.Text>
                  .
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div
          style={{ padding: 20, margin: '3px', borderLeft: '1px solid #ccc' }}
        >
          <Title level={2}>Create Client Account</Title>
          <Form autoComplete="off" form={form}>
            <Form.Item label="CRM Type">
              <Typography.Text>{client?.crmType}</Typography.Text>
            </Form.Item>
            <Form.Item label="Friendly Name:">
              <Typography.Text>{client?.friendlyName}</Typography.Text>
            </Form.Item>
            <Form.Item
              label="Client Name"
              name="client_name"
              rules={[{ required: true, message: 'Client Name is required.' }]}
            >
              <Input onChange={handleClientNameChange} value={clientName} />
            </Form.Item>
            <Form.Item
              label="Account Password"
              name="account_password"
              rules={[
                { required: true, message: 'Account Password is required.' },
              ]}
            >
              <Input onChange={handlePasswordChange} />
            </Form.Item>
            <Form.Item
              label="API Key"
              name="api_key"
              rules={[{ required: true, message: 'API Key is required.' }]}
            >
              <Input onChange={handleApiKeyChange} />
            </Form.Item>
            {/* Only for Sierra */}
            {client?.crmType === 'sierra' && (
              <Form.Item label="Site Name" name="site_name">
                <Input onChange={handleSiteChange} />
              </Form.Item>
            )}
            <Form.Item>
              <SubmitButton form={form} onVerify={handleVerify}></SubmitButton>
            </Form.Item>
            {validationError && (
              <Alert message={validationError} showIcon type="error" />
            )}
          </Form>
        </div>
      </div>
      <Modal
        okText="Start Backfill"
        onCancel={handleVerifyClientCancel}
        onOk={handleVerifyClientOk}
        okButtonProps={{disabled: !smsAccess}}
        open={isModalOpen}
        title={<Title level={2}>Oboarding - Verifying Account</Title>}
      >
        <div>
          Recordings:{' '}
          <Typography.Text strong={true}>
            {recordingsEnabled ? 'Enabled' : 'Disabled'}
          </Typography.Text>
        </div>
        <div>
          SMS Access:{' '}
          <Typography.Text strong={true}>
            {smsAccess ? 'Enabled' : 'Disabled'}
          </Typography.Text>
        </div>
        <Title level={3}>Instructions for SMS Access</Title>
        <p>Send the following message from Follow Up Boss Login:</p>
        <pre style={{ border: '1px solid #ccc', borderRadius: 8, padding: 10 }}>
          {getSmsMessage(client?.friendlyName)}
        </pre>
        <Button onClick={handleCopyMessage} type="primary">
          Copy Message
        </Button>
      </Modal>
      <Modal
        footer={null}
        open={backFillRunning}
        title={<Title level={2}>Running Backfill...</Title>}>
        <Spin size="large" tip="Loading"></Spin>
      </Modal>
    </div>
  );
};

export default VerifyClientPage;

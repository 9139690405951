import { SendOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Popconfirm, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import ChatBubble from '../../components/ChatBubble';
import { Agent, AgentCall } from '../../types';

interface CallProps {
  agent: Agent;
  apiUrl: string;
}
export const Call = ({
  agent,
  apiUrl,
}: CallProps): JSX.Element => {
  const [calls, setCalls] = useState<AgentCall[] | undefined>(
    undefined,
  );
  const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [call, setCall] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const listRef = useRef<HTMLDivElement>(null);
  const shouldScroll = useRef<boolean>(false);
  const [expandedCalls, setExpandedCalls] = useState<Set<string>>(
    new Set(),
  );
  
  const reallai_call_history_url = "https://dashboard.retellai.com/callHistory?history="
  const fetchCalls = async (
    agent: Agent,
    limit: number,
    offset: number,
    reset: boolean,
  ) => {
    try {
      setLoading(true);
      const url = `${apiUrl}/api/calls/agent/${agent.id}?limit=${limit}&offset=${offset}`;
      const response = await fetch(url);
      const jsonData = await response.json();
      if (reset) {
        setOffset(jsonData.length);
        setCalls(jsonData);
        setExpandedCalls(new Set());
      } else {
        setCalls(prev =>
          [...jsonData, ...(prev ?? [])].sort(
            (a, b) =>
              new Date(a.created_at).getTime() -
              new Date(b.created_at).getTime(),
          ),
        );
        setOffset(prev => prev + limit);
      }
    } catch (err) {
      console.error('An error occurred while fetching the call:', err);
      setCalls([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    shouldScroll.current = true;
    fetchCalls(agent, limit, 0, true).then().catch(console.log);
  }, [agent]);

  useEffect(() => {
    if (shouldScroll.current) {
      const timeout = setTimeout(() => {
        if (listRef.current) {
          listRef.current.scrollTop = listRef.current.scrollHeight + 1;
          shouldScroll.current = false;
        }
      }, 500); // Wait for half second
      return () => clearTimeout(timeout);
    }
  }, [calls, offset]);

  const send = async () => {
    try {
      setSendLoading(true);
      const url = `${apiUrl}/api/calls/sms`;
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          agentId: agent.id,
          message: call,
        }),
      });
      if (!response.ok) {
        throw new Error(`Failed to send message ${response.text}`);
      }
      setCall('');
      await fetchCalls(agent, 1, 0, false);
    } catch (err) {
      console.error('An error occurred while fetching the lead:', err);
    } finally {
      setSendLoading(false);
    }
  };

  const toggleExpand = (messageId: string) => {
    setExpandedCalls(prev => {
      const newSet = new Set(prev);
      if (newSet.has(messageId)) {
        newSet.delete(messageId);
      } else {
        newSet.add(messageId);
      }
      return newSet;
    });
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Flex
          ref={listRef}
          style={{ height: '70vh', overflowY: 'auto' }}
          vertical
        >
          <Button
            onClick={() => void fetchCalls(agent, limit, offset, false)}
          >
            Load More
          </Button>
          {calls?.map((call, index) => (
            <>
              <ChatBubble
                isOwnMessage={!call.incoming}
                key={index}
                message={call.transcript}
                onClick={() => toggleExpand(call.id)}
                timestamp={new Date(call.created_at).toLocaleString('en', {timeZone: agent.timezone})} 
              />
              {expandedCalls.has(call.id) && (
                <div
                  style={{
                    marginLeft: '20px',
                    marginTop: '5px',
                    color: 'gray',
                    textAlign: call.incoming ? 'left' : 'right',
                  }}
                >
                  <div>id: {call.id}</div>
                  <div>retell_call_id:  <a href={reallai_call_history_url + call.retell_call_id} rel="noopener noreferrer" target="_blank">{call.retell_call_id}</a></div>
                  <div>Download recording: <a href={call.recording_url}>{call.recording_url}</a></div>
                  <div><audio src={call.recording_url} controls/></div>
                  <div>prompt_result: {JSON.stringify(call.prompt_result, null, 2)}</div>
                  {call.agent_calls?.map((agentCall, index) => (
                    <div>
                    <div>lead_id: {JSON.stringify(agentCall.lead_id, null, 2)}</div>
                    <div>agent call prompt_result: {JSON.stringify(agentCall.prompt_result, null, 2)}</div>
                    </div>
                  ))}
                </div>
              )}
            </>
          ))}
        </Flex>
      </Spin>
    </div>
  );
};

import { Button, Checkbox, Form, Input, Space, Table, Typography } from 'antd';
import { AgentOverrides } from './agent-overrides';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Agent, ClientFeatures } from '../../types';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';

interface AgentFormData {
  agents: Record<string, Agent>;
}

interface AgentSectionProps {
  apiUrl: string;
  clientConfigId: string;
  accessToken: string;
  setError: (error: string | undefined) => void;
  clientFeatures: ClientFeatures;
  clientId: string;
}
export function AgentSection({
  apiUrl,
  clientConfigId,
  accessToken,
  setError,
  clientFeatures,
  clientId,
}: AgentSectionProps) {
  const navigate = useNavigate();
  const [form] = Form.useForm<AgentFormData>();
  const [loading, setLoading] = useState(false);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [isTouched, setIsTouched] = useState(false);
  const [selectedAgentIds, setSelectedAgentIds] = useState<string[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<Agent>();
  const fetchAgents = async () => {
    setLoading(true);
    setError(undefined);
    try {
      const response = await fetch(`${apiUrl}/api/agents/${clientConfigId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(`Failed to fetch agents: ${data.message}`);
      }
      setAgents(data);
      const agentsRecord = data.reduce(
        (acc: Record<string, Agent>, agent: Agent) => {
          acc[agent.id] = agent;
          return acc;
        },
        {},
      );
      form.setFieldsValue({ agents: agentsRecord });
    } catch (err) {
      setError(`${err}`);
    } finally {
      setLoading(false);
    }
  };

  const handleFinish = async (values: AgentFormData) => {
    setLoading(true);
    setError(undefined);
    try {
      const body = Object.keys(values.agents).map(agentId => {
        const agent = values.agents[agentId];
        return {
          id: agentId,
          is_hw_enabled: agent.is_hw_enabled,
          phone_override: agent.phone_override,
          is_isa: agent.is_isa,
        };
      });
      const response = await fetch(`${apiUrl}/api/agents/${clientConfigId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Failed to update agent settings');
      }

      await fetchAgents();
      setIsTouched(false);
    } catch (error) {
      console.error('Error updating agent settings:', error);
      setError(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  const createOnboardingFlow = async () => {
    setLoading(true);
    setError(undefined);
    try {
      const response = await fetch(`${apiUrl}/api/onboarding/flow`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          client_config_id: clientConfigId,
          agent_ids: selectedAgentIds,
        }),
      });
      if (!response.ok) {
        throw new Error(await response.text());
      }

      navigate(`/onboarding/${clientId}`);
    } catch (error) {
      console.error('Error updating agent settings:', error);
      setError(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  const renderTitle = (title: string, tooltip: string) => (
    <Space>
      {title}
      <Typography.Text style={{ fontSize: '14px' }} type="secondary">
        <QuestionCircleOutlined style={{ cursor: 'help' }} title={tooltip} />
      </Typography.Text>
    </Space>
  );

  const rowSelection = {
    selectedRowKeys: selectedAgentIds,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedAgentIds(selectedRowKeys as string[]);
    },
  };

  const columns: ColumnsType<Agent> = [
    {
      title: 'Name',
      key: 'name',
      width: '180px',
      render: (_: unknown, record: Agent) => (
        <Typography.Link onClick={() => setSelectedAgent(record)}>
          <Space>
            <Typography.Text>{record.first_name}</Typography.Text>
            <Typography.Text>{record.last_name}</Typography.Text>
          </Space>
        </Typography.Link>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: '130px',
      render: (_: string, record: Agent) => (
        <Typography.Text>{record.phone || '-'}</Typography.Text>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '200px',
      render: (_: string, record: Agent) => (
        <Typography.Text>{record.email || '-'}</Typography.Text>
      ),
    },
    {
      title: renderTitle(
        'House Whisper Enabled',
        'Enable House Whisper features for this agent',
      ),
      dataIndex: 'is_hw_enabled',
      key: 'is_hw_enabled',
      width: '130px',
      align: 'center',
      render: (_: boolean, record: Agent) => (
        <Form.Item
          name={['agents', record.id, 'is_hw_enabled']}
          style={{ margin: 0, textAlign: 'center' }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      ),
    },
    {
      title: renderTitle(
        'Phone Override',
        'Override the phone number for this agent',
      ),
      dataIndex: 'phone_override',
      key: 'phone_override',
      width: '180px',
      render: (_: string, record: Agent) => (
        <Form.Item
          name={['agents', record.id, 'phone_override']}
          style={{ margin: 0 }}
        >
          <Input placeholder="Enter phone number" />
        </Form.Item>
      ),
    },
    {
      title: renderTitle('ISA', 'Inside Sales Agent'),
      dataIndex: 'is_isa',
      key: 'is_isa',
      width: '100px',
      align: 'center',
      render: (_: boolean, record: Agent) => (
        <Form.Item
          name={['agents', record.id, 'is_isa']}
          style={{ margin: 0, textAlign: 'center' }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      ),
    },
  ];
  useEffect(() => {
    void fetchAgents();
  }, []);
  return (
    <div
      style={{ textAlign: 'left', backgroundColor: 'white', padding: '20px' }}
    >
      <Typography.Title level={2}>Agent Configuration</Typography.Title>
      <Typography.Paragraph>
        Configure House Whisper settings for each agent. Enable or disable House
        Whisper features and set phone number overrides as needed.
      </Typography.Paragraph>
      <Form
        disabled={loading}
        form={form}
        // initialValues={{ agents }}
        onFinish={handleFinish}
        onValuesChange={() => setIsTouched(true)}
      >
        <Table
          columns={columns}
          dataSource={agents}
          pagination={false}
          rowKey="id"
          rowSelection={rowSelection}
        />
        <Space style={{ marginTop: '20px' }}>
          <Button disabled={!isTouched} htmlType="submit" type="primary">
            Save
          </Button>
          <Button
            disabled={selectedAgentIds.length === 0}
            loading={loading}
            onClick={createOnboardingFlow}
            type="primary"
          >
            Create Onboarding
          </Button>
        </Space>
      </Form>
      {selectedAgent && (
        <AgentOverrides
          accessToken={accessToken}
          agent={selectedAgent}
          apiUrl={apiUrl}
          clientFeatures={clientFeatures}
          clientId={clientConfigId}
          onClose={() => setSelectedAgent(undefined)}
          visible={true}
        />
      )}
    </div>
  );
}

import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { Button } from 'antd';

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package
 */

interface SignInButtonProps {
  loginType?: string;
}

export const SignInButton = (props: SignInButtonProps) => {
  const { instance } = useMsal();
  const { loginType } = props;

  const handleLogin = (loginType: string) => {
    const request = { ...loginRequest, redirectUri: window.location.origin };
    if (loginType === 'popup') {
      instance.loginPopup(request).catch(e => {
        console.log(e);
      });
    } else if (loginType === 'redirect') {
      instance.loginRedirect(request).catch(e => {
        console.log(e);
      });
    }
  };
  return (
    <Button onClick={() => handleLogin(loginType ?? 'redirect')}>
      Sign In
    </Button>
  );
};

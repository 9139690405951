// Menu.js
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './NavMenu.css';
import {
  BulbOutlined,
  LoginOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';

import { Button, Menu } from 'antd';

function getItem(label, key, icon, path, action) {
  return {
    key,
    icon,
    label,
    path,
    action,
  };
}

const NavMenu = () => {
  const location = useLocation();
  const agentLeadsPageWithId = /^\/agentLeads\/\w+\/$/;
  const agentLeadsPageWithExtraSegments = /^\/agentLeads\/\w+\/\w+/;
  const clientPageWithId = /^\/client\/.*$/;

  const navigate = useNavigate();
  const { instance, accounts } = useMsal();

  const [collapsed, setCollapsed] = useState(true);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const authMenuItem = accounts[0]
    ? getItem('SignOut', '1', <LogoutOutlined />, null, function () {
        instance.logoutRedirect({
          postLogoutRedirectUri: '/',
        });
      })
    : getItem('SignIn', '1', <LoginOutlined />, null, function () {
        instance.loginPopup(loginRequest).catch(e => {
          console.log(e);
        });
      });

  const items = [
    authMenuItem,
    getItem('Home', '2', <BulbOutlined />, '/', null),
  ];
  if (
    (agentLeadsPageWithId.test(location.pathname) &&
      !agentLeadsPageWithExtraSegments.test(location.pathname)) ||
    clientPageWithId.test(location.pathname)
  ) {
    return (
      <div className="menu-container">
        <div className="button-container">
          <Button
            onClick={toggleCollapsed}
            style={{
              right: 0,
              marginBottom: 6,
            }}
            type="primary"
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        </div>
        {collapsed ? null : (
          <Menu
            defaultOpenKeys={['sub1']}
            defaultSelectedKeys={['1']}
            inlineCollapsed={collapsed}
            items={items}
            mode="inline"
            onClick={({ item, key, keyPath, domEvent }) => {
              if (item.props.action) {
                item.props.action();
              } else {
                const path = item.props.path;
                if (path.includes('http')) {
                  window.open(path, '_blank');
                } else {
                  navigate(path);
                }
              }
            }}
            theme="dark"
          />
        )}
      </div>
    );
  }
  return null;
};

export default NavMenu;

import { useNavigate } from 'react-router-dom';
import { Col, Divider, Input, List, Row, Tabs, Form, Button, Space } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { Agent } from '../../types';

import { Call } from './Call';
import { Conversation } from './Conversation';

const featureLevelOrder = {
  live: 1,
  shadow: 2,
  disabled: 3,
};

type FindByResponse = {
  clientId: string
  agentId: string,
};

const sortAgents = (agents: Agent[]) => {
  return agents.sort((a, b) => {
    const featureLevelComparison =
      featureLevelOrder[a.feature_level] - featureLevelOrder[b.feature_level];
    if (featureLevelComparison !== 0) {
      return featureLevelComparison;
    }
    const firstNameComparison = a.first_name.localeCompare(b.first_name);
    if (firstNameComparison !== 0) {
      return firstNameComparison;
    }
    return a.last_name.localeCompare(b.last_name);
  });
};

export const AgentSmsPage = ({ apiUrl }: { apiUrl: string }): JSX.Element => {
  const { clientId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [agents, setAgents] = useState<Agent[]>([]);
  const [nameSearch, setNameSearch] = useState<string>('');
  const [filteredAgents, setFilteredAgents] = useState<Agent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeAgent, setActiveAgent] = useState<Agent | undefined>(undefined);
  const agentId = searchParams.get('agentId');
  const [friendlyName, setFriendlyName] = useState<string>('');
  const [referralUrl, setReferralUrl] = useState<string>('');
  const [findAgentId, setFindAgentId] = useState<string>('');
  const [findTwillioId, setFindTwillioId] = useState<string>('');
  
  const navigate = useNavigate();

  const handleFindAgentChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setFindAgentId(e.target.value);
  }

  const handleFindAgentClick = async () => {
    const url = `${apiUrl}/api/findbyagent/${findAgentId}`
    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    }).then(async reponse => {
      if (!reponse.ok) {
        throw new Error(`${reponse.status} - ${await reponse.text()}`);
      }
      const findByResponse = (await reponse.json()) as FindByResponse;
      navigate(`/agentSms/${findByResponse.clientId}?agentId=${findByResponse.agentId}`)
    })
    .catch(error => {
      console.error('Error:', error);
      alert(error);
    });
  }

  const handleFindTwillioClick = async () => {
    const url = `${apiUrl}/api/findbytwillio/${findTwillioId}`
    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    }).then(async reponse => {
      if (!reponse.ok) {
        throw new Error(`${reponse.status} - ${await reponse.text()}`);
      }
      const findByResponse = (await reponse.json()) as FindByResponse;
      navigate(`/agentSms/${findByResponse.clientId}?agentId=${findByResponse.agentId}`)
    })
    .catch(error => {
      console.error('Error:', error);
      alert(error);
    });
  }

  const handleFindTwillioChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setFindTwillioId(e.target.value);
  }

  const fetchAgents = async () => {
    try {
      setLoading(true);
      const url = `${apiUrl}/api/configs/${clientId}`;
      const response = await fetch(url);
      const jsonData = await response.json();
      const agents: Agent[] = jsonData.agents;
      if (agentId) {
        const agent = agents.find(agent => agent.id === agentId);
        if (agent) {
          agent.timezone = jsonData.timezone;
          setActiveAgent(agent);
        }
      }
      const friendlyName: string = jsonData.friendly_name;
      if (friendlyName) {
        setFriendlyName(friendlyName);
      }
      const referralUrl: string = jsonData.referral_url;
      if (referralUrl) {
        setReferralUrl(referralUrl);
      }
      const filteredAgents = agents.filter(agent => agent.first_name != null);
      const sortedAgents = filteredAgents.sort((a, b) =>
        a.first_name.localeCompare(b.first_name),
      );
      setAgents(sortedAgents);
    } catch (err) {
      console.error('An error occurred while fetching the agent:', err);
      setAgents([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAgents().then().catch(console.log);
  }, [clientId, agentId]);

  useEffect(() => {
    if (nameSearch === '') {
      setFilteredAgents(agents);
    } else {
      setFilteredAgents(
        agents.filter(agent => {
          return (
            agent.first_name.toLowerCase().includes(nameSearch.toLowerCase()) ||
            agent.last_name.toLowerCase().includes(nameSearch.toLowerCase())
          );
        }),
      );
    }
  });

  const sortedAgents = sortAgents(filteredAgents);

  const { TabPane } = Tabs;

  const [activeKey, setActiveKey] = useState('1');

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <div>
      <br></br>
      <Row gutter={16}>
        <Col sm={{ span: 4, order: 0 }} xs={{ span: 24, order: 1 }}>
          <Input
            onChange={e => setNameSearch(e.target.value)}
            placeholder="Search by name"
            style={{ flex: 0.8 }}
            value={nameSearch}
          />
          <List
            dataSource={sortedAgents}
            loading={loading}
            renderItem={item => {
              let extra_style = {};
              if (item.id == activeAgent?.id) {
                extra_style = { backgroundColor: 'rgba(128, 128, 128, 0.2)' };
              }
              return (
                <List.Item
                  onClick={() => {
                    setActiveAgent(item);
                    setSearchParams({ agentId: item.id });
                  }}
                  style={{
                    textAlign: 'left',
                    ...extra_style,
                  }}
                >
                  {item.first_name} {item.last_name}{' '}
                  {item.is_isa && <span>(ISA)</span>}
                  {item.feature_level === 'shadow' && (
                    <span style={{ fontWeight: 'lighter' }}>
                      (shadow mode, do not send sms)
                    </span>
                  )}
                  {item.feature_level === 'disabled' && (
                    <span style={{ fontWeight: 'lighter' }}>
                      (disabled, do not send sms)
                    </span>
                  )}
                </List.Item>
              );
            }}
            style={{ overflow: 'auto', height: '80vh' }}
          />
          <br></br>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Form>
              <Form.Item
                name="find_by_agent_id"
                layout='vertical'>
                <Input value={findAgentId} onChange={handleFindAgentChange} />
              </Form.Item>
              <br/>
              <Form.Item>
                <Button onClick={handleFindAgentClick}>Find By Agent</Button>
              </Form.Item>
            </Form>
            <Form action={`${apiUrl}/api/findbytwillio/`} method='GET'>
              <Form.Item
                name="find_by_twillio_id"
                layout='vertical'
                rules={[{ required: true, message: 'Twillio Id is required.' }]}>
                <Input value={findTwillioId} onChange={handleFindTwillioChange} />
              </Form.Item>
              <br/>
              <Form.Item>
                <Button onClick={handleFindTwillioClick}>Find By Twillio</Button>
              </Form.Item>
            </Form>
          </Space>
        </Col>
        <Col sm={1} xs={0}>
          <Divider style={{ height: '95%' }} type="vertical" />
        </Col>
        <Col sm={{ span: 17, order: 2 }} xs={{ span: 24, order: 0 }}>
          <h5 style={{ textAlign: 'right' }}>
            {activeAgent && activeAgent.first_name}{' '}
            {activeAgent && activeAgent.last_name}
            {'\u00A0\u00A0\u00A0'}{' '}
            <a href={referralUrl} rel="noopener noreferrer" target="_blank">
              {friendlyName}
            </a>
            {activeAgent && activeAgent.feature_level === 'shadow' && (
              <span style={{ fontWeight: 'lighter' }}>
                (shadow mode, do not send sms)
              </span>
            )}
          </h5>
          <div style={{ fontSize: '13px', textAlign: 'right' }}>
            HW ID: {activeAgent && activeAgent.id}
          </div>
          <div style={{ fontSize: '13px', textAlign: 'right' }}>
            CRM ID: {activeAgent && activeAgent.source_id}
          </div>
          <div style={{ fontSize: '13px', textAlign: 'right' }}>
            {activeAgent && activeAgent.phone}
          </div>
          <div style={{ fontSize: '13px', textAlign: 'right' }}>
            {activeAgent && activeAgent.email}
          </div>
          <Tabs activeKey={activeKey} onChange={handleTabChange}>
            <TabPane
              key="1"
              tab={<span style={{ fontSize: '18px' }}>SMS</span>}
            >
              {activeAgent && (
                <Conversation agent={activeAgent} apiUrl={apiUrl} />
              )}
            </TabPane>
            <TabPane
              key="2"
              tab={<span style={{ fontSize: '18px' }}>Calls</span>}
            >
              {activeAgent && <Call agent={activeAgent} apiUrl={apiUrl} />}
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

import { Checkbox, Space, Tooltip, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  ClientFeatures as ClientFeatureType,
  FeatureValue,
} from '../../types/features';

interface ClientFeaturesProps {
  features: ClientFeatureType;
  toggleFeature: (feature: keyof ClientFeatureType) => void;
}

export function ClientFeatures({
  features,
  toggleFeature,
}: ClientFeaturesProps) {
  // const toggleFeature = (feature: keyof ClientFeatureType) => {
  //   setFeatures(prevFeatures => {
  //     if (prevFeatures[feature] === FeatureValue.ON) {
  //       return { ...prevFeatures, [feature]: FeatureValue.OFF };
  //     }
  //     return { ...prevFeatures, [feature]: FeatureValue.ON };
  //   });
  // };
  // const [features, setFeatures] = useState<ClientFeatureType>({
  //   CONFIRM_APPOINTMENT_CREATION: FeatureValue.OFF,
  //   DISPOSITION_CALL: FeatureValue.OFF,
  // });

  return (
    <div
      style={{ textAlign: 'left', backgroundColor: 'white', padding: '20px' }}
    >
      <Typography.Title level={2}>Client Features</Typography.Title>
      <Space direction="vertical">
        <Checkbox
          checked={features.CONFIRM_APPOINTMENT_CREATION === FeatureValue.ALL}
          onChange={() => toggleFeature('CONFIRM_APPOINTMENT_CREATION')}
        >
          Confirm Appointment Creation{' '}
          <Tooltip title="When enabled, requires confirmation before creating appointments from calls">
            <QuestionCircleOutlined style={{ marginLeft: '4px' }} />
          </Tooltip>
        </Checkbox>
        <Checkbox
          checked={features.DISPOSITION_CALL === FeatureValue.ALL}
          onChange={() => toggleFeature('DISPOSITION_CALL')}
        >
          Call for Disposition{' '}
          <Tooltip title="Enable call disposition functionality for tracking call outcomes">
            <QuestionCircleOutlined style={{ marginLeft: '4px' }} />
          </Tooltip>
        </Checkbox>
      </Space>
    </div>
  );
}

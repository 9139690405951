import { Button, Checkbox, Form, Input, Space, Table, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Stage } from '../../types/stages';
import { ColumnsType } from 'antd/es/table';

interface StageFormData {
  stages: {
    name: string;
    description?: string;
    is_valid_for_ai: boolean;
    is_valid_from_unordered: boolean;
    is_unordered: boolean;
  }[];
}

export function StagesConfig({
  stages,
  setStages,
  apiUrl,
  clientId,
  accessToken,
  setError,
}: {
  stages: Stage[];
  setStages: (stages: Stage[]) => void;
  apiUrl: string;
  clientId: string;
  accessToken: string;
  setError: (error: string | undefined) => void;
}) {
  const [form] = Form.useForm<StageFormData>();
  const [loading, setLoading] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const handleFinish = async (values: StageFormData) => {
    setLoading(true);
    setError(undefined);

    try {
      const response = await fetch(
        `${apiUrl}/api/settings/${clientId}/stages`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(values.stages),
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to update stages: ${await response.text()}`);
      }

      setStages(values.stages);
    } catch (error) {
      setError(`Error updating stages: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const renderTitle = (title: string, tooltip: string) => (
    <Space>
      {title}
      <Typography.Text style={{ fontSize: '14px' }} type="secondary">
        <QuestionCircleOutlined style={{ cursor: 'help' }} title={tooltip} />
      </Typography.Text>
    </Space>
  );

  const columns: ColumnsType<Stage> = [
    {
      title: renderTitle(
        'Valid for AI',
        'Whether this stage can be processed by AI',
      ),
      dataIndex: 'is_valid_for_ai',
      key: 'is_valid_for_ai',
      width: '100px',
      align: 'center',
      render: (_: boolean, record: Stage, index: number) => (
        <Form.Item
          name={['stages', index, 'is_valid_for_ai']}
          style={{ margin: 0, textAlign: 'center' }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      ),
    },
    {
      title: 'Stage Name',
      dataIndex: 'name',
      key: 'name',
      width: '200px',
      render: (name: string, record: Stage, index: number) => (
        <Form.Item
          name={['stages', index, 'name']}
          style={{ margin: 0, textAlign: 'center' }}
        >
          <Typography.Text>{name}</Typography.Text>
        </Form.Item>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '300px',
      render: (description: string, record: Stage, index: number) => (
        <Form.Item
          name={['stages', index, 'description']}
          style={{ margin: 0, textAlign: 'center' }}
        >
          <Input />
        </Form.Item>
      ),
    },

    {
      title: renderTitle(
        'Valid from Unordered',
        'Whether this stage can be reached from an unordered stage',
      ),
      dataIndex: 'is_valid_from_unordered',
      key: 'is_valid_from_unordered',
      width: '120px',
      align: 'center',
      render: (_: boolean, record: Stage, index: number) => (
        <Form.Item
          name={['stages', index, 'is_valid_from_unordered']}
          style={{ margin: 0, textAlign: 'center' }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      ),
    },
    {
      title: renderTitle(
        'Is Unordered',
        'Whether this stage is unordered (not part of the normal flow)',
      ),
      dataIndex: 'is_unordered',
      key: 'is_unordered',
      width: '100px',
      align: 'center',
      render: (_: boolean, record: Stage, index: number) => (
        <Form.Item
          name={['stages', index, 'is_unordered']}
          style={{ margin: 0, textAlign: 'center' }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      ),
    },
  ];

  return (
    <div
      style={{ textAlign: 'left', backgroundColor: 'white', padding: '20px' }}
    >
      <Typography.Title level={2}>Stage Configuration</Typography.Title>
      <Typography.Paragraph>
        Configure the stages for your onboarding process. If valid for AI is not
        checked, all data will be lost on save
      </Typography.Paragraph>
      <Form
        disabled={loading}
        form={form}
        initialValues={{ stages }}
        onFinish={handleFinish}
        onValuesChange={() => setIsTouched(true)}
      >
        <Table
          columns={columns}
          dataSource={stages}
          pagination={false}
          rowKey="name"
          size="middle"
        />
        <div style={{ marginTop: '16px', textAlign: 'right' }}>
          <Button
            disabled={!isTouched}
            htmlType="submit"
            loading={loading}
            type="primary"
          >
            Save Stage Configuration
          </Button>
        </div>
      </Form>
    </div>
  );
}

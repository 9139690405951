import { Button, Form, Modal, Select, Spin, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Agent, AgentFeature, ClientFeatures, FeatureValue } from '../../types';

interface AgentOverridesProps {
  agent: Agent;
  apiUrl: string;
  accessToken: string;
  clientId: string;
  onClose: () => void;
  visible: boolean;
  clientFeatures: ClientFeatures;
}

interface AgentFeatureForm {
  features: {
    id?: string;
    feature_enabled: 'enabled' | 'disabled' | 'none';
    feature_name: string;
  }[];
}

export const AgentOverrides: React.FC<AgentOverridesProps> = ({
  agent,
  apiUrl,
  accessToken,
  onClose,
  visible,
  clientFeatures,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [features, setFeatures] = useState<AgentFeature[]>([]);

  const fetchData = async () => {
    setLoading(true);
    setError(undefined);
    try {
      const response = await fetch(
        `${apiUrl}/api/agents/${agent.id}/features`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`Failed to fetch features: ${data.message}`);
      }

      setFeatures(data);
    } catch (err) {
      setError(`${err}`);
    } finally {
      setLoading(false);
    }
  };

  const handleFinish = async (values: AgentFeatureForm) => {
    setLoading(true);
    setError(undefined);
    try {
      const body = values.features
        .filter(v => v.id || v.feature_enabled !== 'none')
        .map(v => {
          return {
            id: v.id,
            feature_enabled:
              v.feature_enabled === 'none'
                ? null
                : v.feature_enabled === 'enabled',
            feature_name: v.feature_name,
          };
        });

      const response = await fetch(
        `${apiUrl}/api/agents/${agent.id}/features`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(body),
        },
      );

      if (!response.ok) {
        throw new Error('Failed to update feature overrides');
      }

      await fetchData();
    } catch (err) {
      setError(`${err}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      void fetchData();
    }
  }, [visible]);

  return (
    <Modal
      footer={null}
      onCancel={onClose}
      open={visible}
      title={`Feature Overrides - ${agent.first_name} ${agent.last_name}`}
      width={600}
    >
      {error && (
        <Typography.Paragraph style={{ marginBottom: 16 }} type="danger">
          {error}
        </Typography.Paragraph>
      )}
      {loading ? (
        <Spin />
      ) : (
        <Form
          disabled={loading}
          initialValues={{
            features: features.map(feature => ({
              ...feature,
              feature_enabled:
                feature.feature_enabled == null
                  ? 'none'
                  : feature.feature_enabled
                    ? 'enabled'
                    : 'disabled',
            })),
          }}
          onFinish={handleFinish}
        >
          <div
            style={{ maxHeight: '400px', overflowY: 'auto', marginBottom: 16 }}
          >
            <Table
              dataSource={features}
              pagination={false}
              rowKey="id"
              size="small"
            >
              <Table.Column
                dataIndex="feature_name"
                key="feature_name"
                render={(text, feature: AgentFeature, index) => (
                  <>
                    <Form.Item name={['features', index, 'id']}></Form.Item>
                    <Form.Item name={['features', index, 'feature_name']}>
                      <Typography.Text>{text}</Typography.Text>
                    </Form.Item>
                  </>
                )}
                title="Feature"
              />

              <Table.Column
                key="override"
                render={(_, feature: AgentFeature, index) => (
                  <Form.Item
                    name={['features', index, 'feature_enabled']}
                    style={{ marginBottom: 0 }}
                  >
                    <Select
                      options={[
                        { value: 'none', label: 'None' },
                        { value: 'enabled', label: 'On' },
                        { value: 'disabled', label: 'Off' },
                      ]}
                    />
                  </Form.Item>
                )}
                title="Override"
              />
              <Table.Column
                key="client_value"
                render={(_, feature: AgentFeature) => {
                  const defaultValue =
                    clientFeatures[
                      feature.feature_name as keyof ClientFeatures
                    ];
                  return defaultValue !== undefined ? (
                    <Typography.Text type="secondary">
                      {defaultValue === FeatureValue.ALL ? 'On' : 'Off'}
                    </Typography.Text>
                  ) : null;
                }}
                title="Client Value"
              />
            </Table>
          </div>
          <Button disabled={loading} htmlType="submit" type="primary">
            Save
          </Button>
        </Form>
      )}
    </Modal>
  );
};

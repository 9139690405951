import { CheckOutlined, CloseOutlined, TeamOutlined } from '@ant-design/icons';
import { message, Table, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Agent } from '../../types';

const { Title } = Typography;

const ViewConfig: React.FC<{ apiUrl: string }> = ({ apiUrl }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [config, setConfig] = useState<any>(undefined);
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    const fetchConfig = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/api/configs/`);
        if (!response.ok) {
          throw new Error('Failed to fetch leads');
        }
        const data = await response.json();
        const updatedDataObject = Object.fromEntries(
          Object.entries(data).map(([key, value]) => [
            key,
            {
              id: key,
              ...(typeof value === 'object' && value !== null ? value : {}),
              onboarding_page: `../onboarding/${key}`,
            },
          ]),
        );
        setConfig(updatedDataObject);

        // console.log('config:', data);
      } catch (error) {
        message.error('Failed to load leads. Please try again later.');
        console.error('Error fetching leads:', error);
      } finally {
        setLoading(false);
      }
    };

    void fetchConfig();
  }, [apiUrl]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const generateColumns = (data: Record<string, any>) => {
    console.log('Data:', data);
    if (data == null) {
      console.log('Data is empty, returning empty array');
      return [];
    }
    const keys = Object.keys(data['1']).filter(key => key !== 'crm_link');

    // Ensure 'Friendly_name' is the first entry
    const friendlyNameIndex = keys.indexOf('friendly_name');
    if (friendlyNameIndex > -1) {
      keys.splice(friendlyNameIndex, 1);
      keys.unshift('friendly_name');
    }

    return keys.map(key => ({
      title:
        key === 'agents'
          ? 'Test Agent Count'
          : key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
      dataIndex: key,
      key,
      width: 150, // Set a fixed width for each column
      render: (text: any, record: any) => {
        // console.log('Rendering key:', key);
        // console.log('Text:', text);
        // console.log('Record:', record);

        let value = text;
        if (typeof text === 'boolean') {
          value = text ? 'true' : 'false';
        }
        // Handle arrays (like agents or appointment_types)
        if (Array.isArray(text)) {
          value = text
            .map(item => {
              if (typeof item === 'object') {
                // Handle objects within arrays (e.g., appointment_types)
                return item.name ? item.name : JSON.stringify(item);
              }
              return item;
            })
            .join(', ');
        }

        // Handle objects (not arrays) directly
        if (typeof text === 'object' && text !== null) {
          value = JSON.stringify(text); // You may adjust this depending on the structure
        }

        if (key === 'agents' && record[key]) {
          value = `${record[key].length}`;
        }
        if (key === 'referral_url' && record[key]) {
          value = (
            <a
              href={`${record[key]}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              CRM Link
            </a>
          );
        }
        if (key === 'onboarding_page') {
          value = (
            <a href={value} rel="noopener noreferrer" target="_blank">
              Onboarding Page
            </a>
          );
        }
        if (text === 'false' || text === 'disabled') {
          return (
            <Tooltip title="Disabled for Everyone in CRM">
              <CloseOutlined style={{ color: 'red' }} />
            </Tooltip>
          );
        }
        if (text === 'true' || text === 'all') {
          return (
            <Tooltip title="On for Everyone in CRM">
              <CheckOutlined style={{ color: 'green' }} />
            </Tooltip>
          );
        }
        if (text === 'agents') {
          return (
            <Tooltip title="Enabled for Test Agents">
              <TeamOutlined style={{ color: 'blue' }} />
            </Tooltip>
          );
        }
        if (text === 'shadow') {
          return (
            <Tooltip title="In Shadow mode, running but not posting">
              <TeamOutlined style={{ color: 'grey' }} />
            </Tooltip>
          );
        }
        return value;
      },
    }));
  };

  const columns = generateColumns(config);

  interface AppointmentType {
    id: string;
    name: string;
  }

  const featureLevelOrder = {
    live: 1,
    shadow: 2,
    disabled: 3,
  };

  const sortAgentsByFeatureLevel = (agents: Agent[]) => {
    return agents.sort((a, b) => {
      return (
        featureLevelOrder[a.feature_level as 'live' | 'shadow' | 'disabled'] -
        featureLevelOrder[b.feature_level as 'live' | 'shadow' | 'disabled']
      );
    });
  };
  const getRowStyle = (featureLevel: string) => {
    switch (featureLevel) {
      case 'live':
        return {};
      case 'shadow':
        return { backgroundColor: 'lightgray' };
      case 'disabled':
        return { backgroundColor: 'darkgray' };
      default:
        return {};
    }
  };
  const expandedRowRender = (record: any) => {
    console.log('Expanded row record:', record);
    const hasAgents = record.agents && record.agents.length > 0;
    const hasAppointmentTypes =
      record.appointment_types && record.appointment_types.length > 0;
    const shouldDisplayAppointmentTypes =
      hasAppointmentTypes &&
      !(
        record.appointment_types.length === 1 &&
        record.appointment_types[0].name.toLowerCase() === 'other'
      );

    if (hasAgents || shouldDisplayAppointmentTypes) {
      const sortedAgents = sortAgentsByFeatureLevel(record.agents);
      return (
        <div>
          {hasAgents && (
            <div>
              <Title level={3}>Agents</Title>
              <table style={{ width: '20%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'left', padding: '8px' }}>Name</th>
                    <th style={{ textAlign: 'left', padding: '8px' }}>Phone</th>
                    <th style={{ textAlign: 'left', padding: '8px' }}>Email</th>
                    <th style={{ textAlign: 'left', padding: '8px' }}>ISA</th>
                    <th style={{ textAlign: 'left', padding: '8px' }}>
                      CRM ID
                    </th>
                    <th style={{ textAlign: 'left', padding: '8px' }}>HW ID</th>
                    <th style={{ textAlign: 'left', padding: '8px' }}>
                      Feature Level
                    </th>
                    <th style={{ textAlign: 'left', padding: '8px' }}>
                      SMS page
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedAgents.map((agent: Agent, index: number) => (
                    <tr key={index} style={getRowStyle(agent.feature_level)}>
                      <td style={{ textAlign: 'left', padding: '8px' }}>
                        {agent.first_name} {agent.last_name}
                      </td>
                      <td style={{ textAlign: 'left', padding: '8px' }}>
                        {agent.phone}
                      </td>
                      <td style={{ textAlign: 'left', padding: '8px' }}>
                        {agent.email}
                      </td>
                      <td style={{ textAlign: 'left', padding: '8px' }}>
                        {agent.is_isa ? 'Yes' : 'No'}
                      </td>
                      <td style={{ textAlign: 'left', padding: '8px' }}>
                        {agent.source_id}
                      </td>
                      <td style={{ textAlign: 'left', padding: '8px' }}>
                        {agent.id}
                      </td>
                      <td style={{ textAlign: 'left', padding: '8px' }}>
                        {agent.feature_level}
                      </td>
                      <td style={{ textAlign: 'left', padding: '8px' }}>
                        <a
                          href={`../agentSms/${record.client_id}?agentId=${agent.id}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          SMS page
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {shouldDisplayAppointmentTypes && (
            <div>
              <Title level={3}>Appointment Types</Title>
              <ul>
                {record.appointment_types.map(
                  (appointment_type: AppointmentType) => {
                    if (
                      !appointment_type ||
                      typeof appointment_type.name !== 'string'
                    ) {
                      console.error(
                        'Invalid appointment_type:',
                        appointment_type,
                      );
                      return null; // Skip invalid entries
                    }
                    return (
                      <li key={appointment_type.id}>{appointment_type.name}</li>
                    );
                  },
                )}
              </ul>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  const handleExpand = (expanded: boolean, record: any) => {
    const keys = expanded ? [record.id] : [];
    setExpandedRowKeys(keys);
  };

  return (
    <div>
      <Title>Client Configuration</Title>
      <Table
        columns={columns}
        dataSource={config != null ? Object.values(config) : []}
        expandable={{
          expandedRowRender,
          onExpand: (expanded, record) => handleExpand(expanded, record),
          expandedRowKeys: expandedRowKeys,
        }}
        loading={loading}
        rowKey="id"
        scroll={{ x: 'max-content', y: 800 }} // Enable horizontal and vertical scrolling
      />
    </div>
  );
};

export default ViewConfig;

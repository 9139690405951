import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  Select,
  Table,
  Typography,
} from 'antd';

const { Title } = Typography;

type Client = {
  id: string;
  friendlyName: string;
  clientState: string;
};

const ClientsPage: React.FC<{ apiUrl: string }> = ({ apiUrl }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [friendlyName, setFriendlyName] = useState<string>('');
  const [crmType, setCrmType] = useState<string | null>(null);
  const [hasError, setError] = useState<boolean>(false);
  const [timezone, setTimeZone] = useState<string | null>(null);

  const showNewClientModal = () => {
    setIsModalOpen(true);
  };

  const handleFriendlyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFriendlyName(e.target.value);
  };

  const handleCrmTypeChange = (value: string) => {
    setCrmType(value);
  };

  const handleNewClientOk = async () => {
    try {
      const results = await form.validateFields();
      setError(false);
    } catch {
      setError(true);
      return false;
    }
    const response = await fetch(`${apiUrl}/api/clients/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        friendlyName: friendlyName,
        crmType: crmType,
        timezone: timezone
      }),
    });
    setIsModalOpen(false);
    const data = await response.json();
    navigate(`/client/${data.id}/verify`);
  };

  const handleNewClientCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchClients = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/api/clients/`);
        if (!response.ok) {
          throw new Error('Failed to fetch clients');
        }
        const data = (await response.json()) as Client[];

        if (isMounted) {
          setClients(data);
        }
      } catch (error) {
        if (isMounted) {
          setClients([]);
          message.error('Failed to load clients. Please try again later.');
        }
        console.error('Error fetching clients:', error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    void fetchClients();

    return () => {
      isMounted = false;
    };
  }, [apiUrl]);

  const navigate = useNavigate();

  const handleRowClick = async (record: Client) => {
    if (record.clientState === 'created') {
      navigate(`/client/${record.id}/verify`);
    } else if (record.clientState === 'backfill') {
      await message.info('Backfill is running');
    } else if (record.clientState === 'backfill-failed') {
      await message.error('Backfill failed, reach out to engineering');
    } else {
      navigate(`/client/${record.id}/settings`);
    }
  };

  const handleTimezoneChange = (value: string) => {
    setTimeZone(value);
  }

  return (
    <div>
      <Title>Client Configuration</Title>
      <Table
        columns={[
          {
            title: 'Name',
            dataIndex: 'friendlyName',
            key: 'name',
          },
          {
            title: 'Status',
            key: 'status',
            dataIndex: 'clientState',
          },
        ]}
        dataSource={clients}
        loading={loading}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => handleRowClick(record),
          };
        }}
        rowKey="id"
      />
      <Button onClick={showNewClientModal} type="primary">
        Create New Client
      </Button>
      <Modal
        onCancel={handleNewClientCancel}
        onOk={handleNewClientOk}
        open={isModalOpen}
        title={<Title level={2}>Create New Client</Title>}
      >
        <Form autoComplete="off" form={form}>
          <Form.Item
            label="Client Name"
            name="client_name"
            rules={[{ required: true, message: 'Client Name is required.' }]}
          >
            <Input onChange={handleFriendlyNameChange} />
          </Form.Item>
          <Form.Item
            label="CRM Type"
            name="crm_type"
            rules={[{ required: true, message: 'CRM Type is required.' }]}
          >
            <Select
              onChange={handleCrmTypeChange}
              options={[
                { value: 'fub', label: <span>Follow Up Boss</span> },
                { value: 'sierra', label: <span>Sierra</span> },
                { value: 'kvcore', label: <span>KV Core</span> },
              ]}
              placeholder="Select CRM"
              style={{ width: '100%' }}
            ></Select>
          </Form.Item>
          <Form.Item name="timezone" label="Timezone" rules={[{ required: true, message: 'Timezone is required.' }]}>
            <Select onChange={handleTimezoneChange} placeholder="Select timezone" options={[
              { "value": "UTC", "label": "UTC" },
              { "value": "America/New_York", "label": "America/New_York" },
              { "value": "America/Chicago", "label": "America/Chicago" },
              { "value": "America/Denver", "label": "America/Denver" },
              { "value": "America/Los_Angeles", "label": "America/Los_Angeles" },
              { "value": "America/Anchorage", "label": "America/Anchorage" },
              { "value": "America/Phoenix", "label": "America/Phoenix" },
              { "value": "Pacific/Honolulu", "label": "Pacific/Honolulu" },
              { "value": "Europe/London", "label": "Europe/London" },
              { "value": "Europe/Paris", "label": "Europe/Paris" },
              { "value": "Europe/Berlin", "label": "Europe/Berlin" },
              { "value": "Europe/Madrid", "label": "Europe/Madrid" },
              { "value": "Europe/Rome", "label": "Europe/Rome" },
              { "value": "Asia/Tokyo", "label": "Asia/Tokyo" },
              { "value": "Asia/Shanghai", "label": "Asia/Shanghai" },
              { "value": "Asia/Hong_Kong", "label": "Asia/Hong_Kong" },
              { "value": "Asia/Singapore", "label": "Asia/Singapore" },
              { "value": "Asia/Seoul", "label": "Asia/Seoul" },
              { "value": "Asia/Kolkata", "label": "Asia/Kolkata" },
              { "value": "Asia/Dubai", "label": "Asia/Dubai" },
              { "value": "Australia/Sydney", "label": "Australia/Sydney" },
              { "value": "Australia/Melbourne", "label": "Australia/Melbourne" },
              { "value": "Australia/Brisbane", "label": "Australia/Brisbane" },
              { "value": "Australia/Perth", "label": "Australia/Perth" }
            ]} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ClientsPage;

import React from 'react';
import { Button, Form, Input, Space, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

export interface CredentialConfig {
  name?: string;
  username: string;
  password: string;
}

interface ExternalApiConfigProps {
  title: string;
  type: string;
  description?: string;
}

export const ExternalApiConfig: React.FC<ExternalApiConfigProps> = ({
  title,
  type,
  description,
}) => {
  return (
    <div style={{ textAlign: 'left', marginBottom: '16px' }}>
      <Typography.Title level={4}>{title}</Typography.Title>
      <Typography.Paragraph>{description}</Typography.Paragraph>
      <Form.List name={`${type}Credentials`}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                align="baseline"
                key={key}
                style={{ display: 'flex', marginBottom: 8 }}
              >
                <Form.Item
                  {...restField}
                  name={[name, 'username']}
                  rules={[{ required: true, message: 'Username is required' }]}
                >
                  <Input placeholder="Username" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'password']}
                  rules={[{ required: true, message: 'Password is required' }]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button
                block
                icon={<PlusOutlined />}
                onClick={() => add()}
                type="dashed"
              >
                Add Credential
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};

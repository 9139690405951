// Enum for available features
export enum Features {
  SISU_INTEGRATION = 'SISU_INTEGRATION',
  ZILLOW_INTEGRATION = 'ZILLOW_INTEGRATION',
  REALTOR_INTEGRATION = 'REALTOR_INTEGRATION',
  // Add more features as needed
}

export enum FeatureValue {
  ALL = 'all',
  DISABLED = 'disabled',
}

export interface ClientFeatures {
  PRODUCTION: FeatureValue;
  CONFIRM_APPOINTMENT_CREATION: FeatureValue;
  DISPOSITION_CALL: FeatureValue;
}

// Interface representing a client feature
export interface ClientFeature {
  id: number;
  clientConfigId: number;
  featureName: Features;
  featureValue: FeatureValue;
  createdAt: Date;
  updatedAt: Date;
}

// Interface for creating/updating a client feature
export interface ClientFeatureInput {
  clientConfigId: number;
  featureName: Features;
  featureValue: FeatureValue;
}

export interface AgentFeature {
  id?: string;
  feature_name: string;
  feature_enabled?: boolean;
}
